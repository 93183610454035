<template>
  <b-card
    no-body
    class="card-apply-job mb-1 p-0"
  >

    <b-card-body>
      <h5 class="apply-job-title mb-2">
        Nowe funkcje w programie (ver 1.3 z dnia 11.03.2022)
      </h5>

      <!-- payment  -->
      <div class="apply-job-package bg-light-primary rounded">
        <ul>
          <li>Dodano możliwość filtrowania faktur</li>
          <li>Dodano możliwość wystawiania faktury proforma</li>
          <li>Z poziomu aplikacji można dodawać umowy: o pracę , o dzieło lub zlecenie</li>
        </ul>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BCardText, BCardBody, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
  },
  directives: {
    Ripple,
  },
}
</script>
