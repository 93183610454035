<template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
      <CardAdvanceCongratulation />
    </b-col>

    <b-col
      lg="5"
      md="6"
      class="d-none"
    >
      <CardAdvanceMedal />
    </b-col>

  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import CardAdvanceMedal from './CardAdvanceMedal.vue'
import CardAdvanceCongratulation from './CardAdvanceCongratulation.vue'

export default {
  components: {
    BRow,
    BCol,

    CardAdvanceCongratulation,
    CardAdvanceMedal,

  },
}
</script>
