<template>

  <div class="main-dashboard--wrapper">

    <b-row>
      <b-col>
        <Hello />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <CardStatistic />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <Chart />
      </b-col>

    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <Revenues />
        <UpdatesInfo />

      </b-col>
    </b-row>

  </div>

</template>

<script>

import { BCol, BRow } from 'bootstrap-vue'

import Hello from './Hello.vue'

import CardStatistic from './CardStatistic.vue'
import Chart from './Chart.vue'
import Revenues from './Revenues.vue'
import UpdatesInfo from './UpdatesInfo.vue'

export default {
  components: {
    BCol,
    BRow,
    Hello,
    CardStatistic,
    Chart,
    Revenues,
    UpdatesInfo,
  },

}
</script>

<style>

</style>
